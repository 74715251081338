import { gsap } from "gsap";
import { Flip } from "gsap/Flip";

gsap.registerPlugin(Flip);

export class FilterGrid {
  constructor() {
    this.checkboxAll = document.querySelector("#all");
    this.filters = gsap.utils.toArray(".filter");
    this.items = gsap.utils.toArray(".item");
    this.filterClasses = this.filters.map((element) => "." + element.id);
    this.setup();
  }

  setup() {
    if (this.checkboxAll && this.filters) {
      this.filters.forEach((btn) =>
        btn.addEventListener("click", (e) => {
          this.updateFilters(e.target);
        })
      );
      this.checkboxAll.addEventListener("click", (e) => {
        this.filters.forEach(
          (checkbox) => (checkbox.checked = this.checkboxAll.checked)
        );
        this.updateFilters(e.target);
      });
    }
  }

  updateFilters = (el) => {
    // get current state
    const state = Flip.getState(this.items);

    // unset any checked boxes
    this.filters.forEach((checkbox) => (checkbox.checked = false));
    this.checkboxAll.checked = false;
    el.checked = true;

    // get the selected filtered classes
    let classes = this.filters
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => "." + el.id);

    // get all filter classes
    if (this.checkboxAll.checked) {
      classes = this.filterClasses;
    }

    // get matches
    const matches = classes.length
      ? gsap.utils.toArray(classes.join(","))
      : classes;

    // adjust the display property of each item ("none" for filtered ones, "inline-flex" for matching ones)
    this.items.forEach(
      (item) =>
        (item.style.display =
          matches.indexOf(item) === -1 ? "none" : "inline-flex")
    );

    // animate from the previous state
    Flip.from(state, {
      duration: 1,
      scale: true,
      absolute: false,
      ease: "power2.inOut",
      onEnter: (elements) =>
        gsap.fromTo(
          elements,
          { opacity: 0, y: 16 },
          { opacity: 1, y: 1, duration: 0.6, stagger: 0.1 }
        ),
      onLeave: (elements) =>
        gsap.to(elements, { opacity: 0, y: 0, duration: 0.6, stagger: 0.1 }),
    });
  };
}
