import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import Lenis from "lenis";

export class ScrollSmooth {
  constructor() {
    this.init();
  }
  init() {
    const lenis = new Lenis({ lerp: 0.04 });
    lenis.on("scroll", () => ScrollTrigger.update());
    const scrollFn = (time) => {
      lenis.raf(time);
      requestAnimationFrame(scrollFn);
    };
    requestAnimationFrame(scrollFn);
  }
}
