import "flowbite";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({ ignoreMobileResize: true });

import { ScrollSmooth } from "./js/ScrollSmooth";
import { ScrollReveal } from "./js/ScrollReveal";
import { ScrollCarousel } from "./js/ScrollCarousel";
import { FilterGrid } from "./js/FilterGrid";
import { FilterDarkMode } from "./js/FilterDarkMode";
import { onLoad } from "./js/onLoad";
import { hackTable, safariGlassmorphismFix } from "./js/Utils";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

new FilterDarkMode().checkLocalStorage();

window.addEventListener("load", (e) => {
  safariGlassmorphismFix();
  hackTable();
  new ScrollSmooth();
  new ScrollCarousel();
  new ScrollReveal();
  new FilterGrid();
  new FilterDarkMode().init();
  onLoad();
});
