import { gsap } from "gsap";
import { delay } from "./Utils";

const onLoad = () => {
  delay(1000).then(() => {
    gsap.fromTo(
      document.querySelector("main"),
      { y: 100, opacity: 0, willChange: "transform, opacity" },
      { y: 0, ease: "power3", opacity: 1, duration: 1 }
    );
    document.querySelector(".preloader").remove();
  });
};

export { onLoad };
