const delay = (n) => {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
};

const safariGlassmorphismFix = () => {
  if (/apple/i.test(navigator.vendor)) {
    document
      .querySelector("header")
      .style.setProperty("-webkit-backdrop-filter", "blur(24px)");
  }
};

const hackTable = () => {
  const rows = document.querySelectorAll("tr");
  rows.forEach((row) => {
    const tds = row.querySelectorAll("td");
    let allEmpty = true;
    tds.forEach((td, index) => {
      if (index !== 0 && td.innerHTML !== "") {
        allEmpty = false;
      }
    });
    if (allEmpty && tds[0] !== undefined) {
      tds[0].classList.add(
        "text-lg",
        "font-bold",
        "text-body_bg",
        "dark:text-white"
      );
    }
  });
};

export { delay, safariGlassmorphismFix, hackTable };
