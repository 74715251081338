import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class ScrollReveal {
  constructor() {
    this.sections = gsap.utils.toArray("section");
    this.init();
  }
  init() {
    gsap.set(this.sections, {
      y: 100,
      opacity: 0,
      willChange: "transform, opacity",
    });
    this.sections.forEach((section, i) => {
      gsap.to(section, {
        opacity: 1,
        y: 0,
        ease: "power3",
        overwrite: "auto",
        scrollTrigger: {
          trigger: section,
          start: "top bottom",
          end: "top center",
          //markers: true,
          scrub: 2,
        },
      });
    });
  }
}
