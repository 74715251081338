import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export class FilterDarkMode {
  constructor() {
    this.lightSwitch = document.getElementById("light-switch");
    this.htmlElement = document.documentElement;
  }

  checkLocalStorage() {
    if (
      localStorage.getItem("theme") === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      this.htmlElement.classList.add("dark");
      this.lightSwitch.checked = true;
    } else {
      this.htmlElement.classList.remove("dark");
      this.lightSwitch.checked = false;
    }
  }

  init() {
    if (this.lightSwitch) {
      this.lightSwitch.addEventListener("change", (e) => {
        if (this.lightSwitch.checked) {
          this.htmlElement.classList.add("dark");
          localStorage.setItem("theme", "dark");
        } else {
          this.htmlElement.classList.remove("dark");
          localStorage.setItem("theme", "light");
        }
      });
    }
  }
}
